<template>
  <div>
    <div class="center">
      <vue-plyr
        v-if="clipData.clipVideoUrl"
        :options="options"
      >
        <video
          controls
          crossorigin
          playsinline
          autplay
        >
          <source
            size="1080"
            :src="`https://vod.brimecdn.com/n/ax9rag1oiyr8/b/brime/o/clip/${clipData.channelID}/${clipData._id}`"
            type="video/mp4"
          >
        </video>
      </vue-plyr><br>
      <h4 v-if="clipData.stream.title">
        {{ clipData.stream.title }}
      </h4>
      Channel: <router-link :to="'/'+ clipChannelName.channel">
        {{ clipChannelName.channel }}
      </router-link> // {{ clipData.clipName }}<br>
      <router-link :to="'/category/'+clipData.stream.category.name">
        {{ clipData.stream.category.name }}
      </router-link>
    </div>
  </div>
</template>
<script>

/*  eslint-disable */ 
export default {
    props: {
    id: {
      type: String,
      default: () => {},
    },
  },
  async mounted () {
  window.brime.getClipInfo(this.$props.id)
.then(async (info) => {
    this.clipData = info
    this.clipChannel = this.clipData.channelID
    this.clipChannelName = await window.brime.getChannel(this.clipChannel)
        // self.VuePlyr(this.clipData.clipVideoUrl, {
        //   autoplay: true,
        //   fullscreen: {
        //     enabled: true,
        //     iosNative: true,
        //   },
        //   controls: [
        //     "play-large",
        //     "play",
        //     "mute",
        //     "volume",
        //     'airplay',
        //     "fullscreen",
        //   ],
        //   muted: false,
        //   storage: {
        //     enabled: false,
        //   },
        // });
})

  },
    data: function() {
      return {
       clipChannelName: {},   
       clipData: {
       stream: {title: '', category: ''}    
       },
        options: {autoplay: true, controls: [
          "play-large",
          "play",
          "progress",
          "current-time",
          "download",
          "mute",
          "volume",
          "settings",
          "fullscreen",
        ],},
      }
  }
}
</script>
<style scoped>
video{
width: 100%;
}
/* Small screens */
@media all and (max-width: 767px) {
.center{
  margin: auto;
  width: 100%;
  padding: 0px;
  }
}
/* Medium and large screens */
@media all and (min-width: 768px) {
.center{
  margin: auto;
  width: 70%;
  padding: 10px;
  }
}
</style>